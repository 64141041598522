<template>
  <div>
    <v-container fluid grid-list-md text-xs-center class="pa-0">
      <v-row align="center" justify="center"  fill-height>
        <v-col cols="12" md="3" v-for="card in totalizadoresOrdenados" :key="card.titulo">
          <v-card :style="{'background-color': `${card.cor}`}">
            <v-card-title>
              <v-row class="headline white--text">
                <v-col offset="2" cols="8" class="text-center pt-0" style="word-break: break-word;">{{ $tc(card.titulo, 1)}}</v-col>
                <v-col cols="2" class="text-right pt-0"><tooltip-ajuda-lista :titulo="`${$tc('label.status', 1)}: `" :lista="card.statusComposicao || statusPadroes[card.status]" /></v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-1">
              <v-row class="headline white--text">
                <v-col cols="12" class="text-center pa-0">{{ getMoney(card.valorMonetario) }}</v-col>
                <v-col cols="12" class="text-center pa-0">{{  getPercent(percentualExibido === 'quantidade' ? card.valorPercentualQtd : card.valorPercentual) }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TooltipAjudaLista from '../../shared-components/TooltipAjudaLista';

import {
  getMoney,
  getPercent,
} from '../../common/functions/helpers';
import { listarPagamentosTotalizadores } from '../../common/resources/liquidacao/pagamento';

export default {
  name: 'LiquidacaoCardsTotalizadores',
  components: {
    TooltipAjudaLista,
  },
  props: {
    filtros: Object,
    percentual: String,
    somaContratos: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lista: [],
      percentualExibido: 'monetario',
      filtrosAplicados: this.filtros,
      statusPadroes: {
        PAGAMENTO: ['status_entidade.liquidado', 'status_entidade.aguardando_pagamento', 'status_entidade.programado', 'status_entidade.aprovado', 'status_entidade.pendente'],
        PENDENTE_APURACAO: ['status_entidade.bloqueado', 'status_entidade.aguardando_apuracao', 'status_entidade.em_analise'],
        REJEITADA: ['status_entidade.cancelado', 'status_entidade.reprovado', 'status_entidade.vencido'],
      },
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.buscaTotalizadores();
      },
    },
  },
  computed: {
    totalizadoresOrdenados() {
      function compare(a, b) {
        if (a.ordenacao < b.ordenacao) {
          return -1;
        }
        if (a.ordenacao > b.ordenacao) {
          return 1;
        }
        return 0;
      }

      return this.lista.sort(compare);
    },
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    usuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
  },
  methods: {
    getMoney,
    getPercent,
    buscaTotalizadores() {
      if (this.percentual) {
        this.percentualExibido = this.percentual;
      }
      const params = {
        ...this.filtrosAplicados,
        visao: 'visao_contrato',
      };

      listarPagamentosTotalizadores(params, this.$resource)
        .then((response) => {
          const dadosRetornados = response.data;
          this.tratarCards(dadosRetornados);
          this.tratarCores();
        })
        .catch((err) => {
          if (err.data) {
            this.$toast(err.data.error);
          }
        });
    },

    tratarCores() {
      this.lista.forEach((item) => {
        if (item.status === 'PAGAMENTO') {
          item.cor = 'rgb(26, 188, 156)';
          item.ordenacao = 1;
        } else if (item.status === 'PENDENTE_APURACAO') {
          item.cor = 'rgb(230, 126, 34)';
          item.ordenacao = 2;
        } else if (item.status === 'REJEITADA') {
          item.cor = 'rgb(231, 76, 60)';
          item.ordenacao = 3;
        }
      });
    },

    tratarCards(dadosRetornados) {
      const cards = [];

      cards.push({
        status: 'PAGAMENTO',
        titulo: this.$tc('label.pagamento', 1),
      });

      cards.push({
        status: 'PENDENTE_APURACAO',
        titulo: this.$tc('label.pagamento_pendente', 1),
      });

      cards.push({
        status: 'REJEITADA',
        titulo: this.$tc('label.pagamento_rejeitado_totalizador', 1),
      });

      this.lista = cards.map((card) => {
        const valores = dadosRetornados
          .filter((dados) => dados.status === card.status)[0] || {};

        return {
          ...card,
          statusComposicao: valores.statusComposicao,
          valorMonetario: valores.valorMonetario || 0,
          valorPercentual: valores.valorPercentual || 0,
          valorPercentualQtd: valores.valorPercentualQtd || 0,
        };
      });
    },
  },
};
</script>
